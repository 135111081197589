import React, { useState } from 'react'
import { FOOTER_SIGN_UP_NEWSLETTER_CLICKED } from '../../helpers/events'
import SubscribeButton from '../SubscribeButton'

const navigation = {
  solutions: [
    { name: 'Testimonials', href: '/#testimonials' },
    { name: 'Features', href: '/#features' },
    { name: 'Compare', href: '/#compare' },
  ],
  support: [
    { name: 'Pricing', href: '/#pricing' },
    { name: 'FAQ', href: '/#faq' },
    { name: 'Sitemap', href: '/sitemap' },
  ],
  company: [
    { name: 'About', href: '/about' },
    { name: 'Blog', href: '/blog' },
    { name: 'Contact', href: 'mailto:stefan@tubetranscripts.com' },
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms' },
  ],
  tools: [
    {
      name: 'YouTube Video Analyzer',
      href: '/tools/free-youtube-video-analyzer',
    },
    // {
    //   name: 'YouTube Thumbnail Downloader',
    //   href: '/tools/free-youtube-thumbnail-downloader',
    // },
    {
      name: 'YouTube Subtitles Downloader',
      href: '/tools/free-youtube-subtitles-downloader',
    },
    // {
    //   name: 'YouTube Niche Reports',
    //   href: '/tools/free-youtube-niche-reports',
    // },
  ],
  social: [
    {
      name: 'Twitter',
      href: 'https://twitter.com/YTTranscripts',
      icon: (props) => (
        <svg viewBox="0 0 64 64" width="32" height="32" {...props}>
          <circle cx="32" cy="32" r="31" fill="currentColor"></circle>
          <path
            d="M48,22.1c-1.2,0.5-2.4,0.9-3.8,1c1.4-0.8,2.4-2.1,2.9-3.6c-1.3,0.8-2.7,1.3-4.2,1.6 C41.7,19.8,40,19,38.2,19c-3.6,0-6.6,2.9-6.6,6.6c0,0.5,0.1,1,0.2,1.5c-5.5-0.3-10.3-2.9-13.5-6.9c-0.6,1-0.9,2.1-0.9,3.3 c0,2.3,1.2,4.3,2.9,5.5c-1.1,0-2.1-0.3-3-0.8c0,0,0,0.1,0,0.1c0,3.2,2.3,5.8,5.3,6.4c-0.6,0.1-1.1,0.2-1.7,0.2c-0.4,0-0.8,0-1.2-0.1 c0.8,2.6,3.3,4.5,6.1,4.6c-2.2,1.8-5.1,2.8-8.2,2.8c-0.5,0-1.1,0-1.6-0.1c2.9,1.9,6.4,2.9,10.1,2.9c12.1,0,18.7-10,18.7-18.7 c0-0.3,0-0.6,0-0.8C46,24.5,47.1,23.4,48,22.1z"
            fill="white"></path>
        </svg>
      ),
    },
    {
      name: 'Reddit',
      href: 'https://www.reddit.com/r/youtuberhacks/',
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="currentWidth"
          height="currentHeight"
          viewBox="0 0 24 24"
          {...props}>
          <path
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.238 15.348c.085.084.085.221 0 .306-.465.462-1.194.687-2.231.687l-.008-.002-.008.002c-1.036 0-1.766-.225-2.231-.688-.085-.084-.085-.221 0-.305.084-.084.222-.084.307 0 .379.377 1.008.561 1.924.561l.008.002.008-.002c.915 0 1.544-.184 1.924-.561.085-.084.223-.084.307 0zm-3.44-2.418c0-.507-.414-.919-.922-.919-.509 0-.923.412-.923.919 0 .506.414.918.923.918.508.001.922-.411.922-.918zm13.202-.93c0 6.627-5.373 12-12 12s-12-5.373-12-12 5.373-12 12-12 12 5.373 12 12zm-5-.129c0-.851-.695-1.543-1.55-1.543-.417 0-.795.167-1.074.435-1.056-.695-2.485-1.137-4.066-1.194l.865-2.724 2.343.549-.003.034c0 .696.569 1.262 1.268 1.262.699 0 1.267-.566 1.267-1.262s-.568-1.262-1.267-1.262c-.537 0-.994.335-1.179.804l-2.525-.592c-.11-.027-.223.037-.257.145l-.965 3.038c-1.656.02-3.155.466-4.258 1.181-.277-.255-.644-.415-1.05-.415-.854.001-1.549.693-1.549 1.544 0 .566.311 1.056.768 1.325-.03.164-.05.331-.05.5 0 2.281 2.805 4.137 6.253 4.137s6.253-1.856 6.253-4.137c0-.16-.017-.317-.044-.472.486-.261.82-.766.82-1.353zm-4.872.141c-.509 0-.922.412-.922.919 0 .506.414.918.922.918s.922-.412.922-.918c0-.507-.413-.919-.922-.919z"
          />
        </svg>
      ),
    },
    {
      name: 'YouTube',
      href: 'https://www.youtube.com/channel/UCHIsuXJEjRsiy153-xKIsyg',
      icon: (props) => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 415.2 415.2"
          height="currentHeight"
          width="currentWidth"
          fill="currentColor"
          {...props}>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path d="M207.6,0C92.9,0,0,93,0,207.6S92.9,415.2,207.6,415.2s207.6-93,207.6-207.6S322.3,0,207.6,0ZM330.7,243.1A47.89,47.89,0,0,1,283,290.8H132.2a47.89,47.89,0,0,1-47.7-47.7v-71a47.89,47.89,0,0,1,47.7-47.7H283a47.89,47.89,0,0,1,47.7,47.7v71Z" />
              <polygon points="179.4 177 179.4 238.2 235.8 207.9 179.4 177" />
            </g>
          </g>
        </svg>
      ),
    },
  ],
}

export default function Footer() {
  const [email, setEmail] = useState('')

  return (
    <footer className="mt-16 bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Product
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Support
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.support.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Company
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
                  Free Tools
                </h3>
                <ul role="list" className="mt-4 space-y-4">
                  {navigation.tools.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="text-base text-gray-500 hover:text-gray-900">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-8 xl:mt-0">
            <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">
              Subscribe to our newsletter
            </h3>
            <p className="mt-4 text-base text-gray-500">
              Get help growing your YouTube channel.
            </p>
            <form className="mt-4 sm:flex sm:max-w-md">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                type="email"
                name="email-address"
                id="email-address"
                autoComplete="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                className="w-full min-w-0 px-4 py-2 text-base text-gray-900 placeholder-gray-500 bg-white border border-gray-300 rounded-full shadow-sm appearance-none focus:outline-none focus:ring-purple-500 focus:border-purple-500 focus:placeholder-gray-400"
                placeholder="Enter your email"
              />
              <div className="mt-3 rounded-full sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <SubscribeButton
                  listId="e0fb95d7-9487-4b77-b3d9-c50cf99aa7b1"
                  className="flex items-center justify-center w-full px-4 py-2 text-base font-medium text-white bg-purple-600 border border-transparent rounded-full hover:bg-purple-700 focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                  disabled={!email}
                  onEmail={() => email}
                  event={FOOTER_SIGN_UP_NEWSLETTER_CLICKED}
                />
              </div>
            </form>
          </div>
        </div>
        <div className="pt-8 mt-8 border-t border-gray-200 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            {navigation.social.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="text-gray-400 hover:text-gray-500">
                <span className="sr-only">{item.name}</span>
                <item.icon className="w-6 h-6" aria-hidden="true" />
              </a>
            ))}
          </div>
          <p className="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
            Made with ❤️ and ☕️ by the{' '}
            <a
              className="underline"
              target="_blank"
              rel="noreferrer"
              href="https://nichemat.es">
              Niche Mates
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}
