import React, { useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import useLocalStorageState from '../useLocalStorageState'
import { COOKIE_CONSENT_LOCAL_STORAGE_KEY } from '../../helpers/constants'

// Note: For this to work we need the custom animations in tailwind.config.js
export default function useCookieConsent() {
  const [hasConsent, setHasConsent] = useLocalStorageState(
    COOKIE_CONSENT_LOCAL_STORAGE_KEY,
    null,
  )

  useEffect(() => {
    if (hasConsent !== null) {
      return
    }

    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? 'animate-enter' : 'animate-leave'
          } max-w-lg bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 sm:text-gray-800 sm:mb-10`}>
          <div className="flex items-center px-4">
            <p className="text-sm font-medium text-gray-900">🍪</p>
            <p className="ml-2 text-sm text-gray-600">
              Cool if we use cookies?
            </p>
          </div>
          <div className="flex border-l border-gray-200">
            <button
              onClick={() => toast.dismiss(t.id)}
              className="flex items-center justify-center w-full p-4 text-sm font-medium text-gray-500 border border-transparent rounded-none rounded-r-lg hover:text-gray-800 focus:outline-none">
              Na
            </button>
            <button
              onClick={() => {
                setHasConsent(true)
                toast.dismiss(t.id)
              }}
              className="flex items-center justify-center w-full p-4 text-sm font-medium text-purple-500 border-l border-transparent border-gray-300 rounded-none rounded-r-lg hover:text-purple-800 focus:outline-none focus:ring-2 focus:ring-indigo-500">
              Yes <span className="ml-1">🙏</span>
            </button>
          </div>
        </div>
      ),
      { duration: Infinity },
    )
  }, [hasConsent, setHasConsent])

  return {
    hasConsent,
    optedOut: hasConsent === false,
    component: () => (
      <Toaster
        position={window.innerWidth < 640 ? 'top-center' : 'bottom-center'}
      />
    ),
  }
}
