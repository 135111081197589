import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Checkmark from '../../assets/checkmark.svg'
import Spinner from '../../assets/spinner.svg'
import Warning from '../../assets/warning.svg'
import useAmplitude from '../../hooks/useAmplitude'

export default function SubscribeButton({
  listId,
  onEmail,
  disabled,
  className,
  event,
}) {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const { logEvent } = useAmplitude()

  const onClick = async () => {
    const email = onEmail()

    if (!email) {
      return
    }

    setLoading(true)
    setError(false)
    setSuccess(false)
    logEvent(event, { email })

    try {
      await fetch(
        'https://us-central1-nichemates-75f78.cloudfunctions.net/addToList',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ listId, email }),
        },
      )
      setSuccess(true)
    } catch (err) {
      setError(true)
    } finally {
      setLoading(false)
      setTimeout(() => {
        setError(false)
        setSuccess(false)
      }, 2000)
    }
  }

  const getButtonContent = () => {
    if (loading) {
      return <Spinner className="text-white" width={24} height={24} />
    }

    if (error) {
      return <Warning className="text-white" width={24} height={24} />
    }

    if (success) {
      return <Checkmark className="text-green-500" width={24} height={24} />
    }

    return 'Subscribe'
  }

  const getButtonColors = () => {
    if (loading) {
      return 'bg-gray-500'
    }

    if (error) {
      return 'bg-red-500'
    }

    if (success) {
      return 'bg-green-500'
    }

    return 'bg-purple-500'
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled || loading}
      className={`flex justify-center flex-shrink-0 py-3 text-sm font-medium tracking-widest uppercase text-center items-center text-white disabled:bg-gray-500 focus:ring-4 focus:ring-opacity-50 focus:ring-offset-2 focus:outline-none rounded-full ${getButtonColors()} ${className}`}>
      {getButtonContent()}
    </button>
  )
}

SubscribeButton.propTypes = {
  listId: PropTypes.string,
  onEmail: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  event: PropTypes.string,
}
