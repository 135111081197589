import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Logo from '../../assets/logo.svg'
import useAmplitude from '../../hooks/useAmplitude'
import { INSTALL_CLICKED, SEND_LINK_CLICKED } from '../../helpers/events'
import Hamburger from '../../assets/hamburger.svg'

export default function Header() {
  const [showMenu, setShowMenu] = useState(false)
  const { logEvent } = useAmplitude()
  const router = useRouter()

  useEffect(() => {
    if (!showMenu) {
      return
    }
    setShowMenu(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.route])

  return (
    <section className="w-full px-8 text-gray-700 bg-white body-font">
      <nav className="relative py-6 mx-auto max-w-7xl lg:flex lg:justify-between lg:items-center">
        <div className="relative z-20 flex items-center justify-between ml-0 md:ml-1 lg:ml-2">
          <p className="flex items-center relative -mt-0.5 order-first font-medium text-gray-900 lg:order-none lg:w-auto title-font lg:items-center lg:justify-center">
            <span className="z-20 text-xl leading-none text-gray-800 select-none -headline logo">
              <Link href="/">
                <a>
                  <Logo />
                </a>
              </Link>
            </span>
          </p>

          <div className="flex lg:hidden">
            <button
              onClick={() => setShowMenu(!showMenu)}
              type="button"
              className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
              aria-label="toggle menu">
              <Hamburger height="24" width="24" />
            </button>
          </div>
        </div>
        <div
          className={`absolute z-10 left-0 items-center justify-between w-full h-full font-medium lg:justify-center lg:flex ${
            showMenu ? 'block' : 'hidden'
          }`}>
          <div className="flex flex-col p-5 mt-4 space-y-5 text-lg bg-white rounded-lg shadow-xl lg:text-base lg:space-y-0 lg:shadow-none lg:bg-transparent lg:rounded-none lg:p-0 lg:mt-0 lg:flex-row lg:space-x-10 xl:space-x-16">
            <Link href="/#faq">
              <a className="mr-6 font-medium leading-6 text-gray-500 hover:text-gray-900">
                FAQs
              </a>
            </Link>
            <Link href="/about">
              <a className="mr-6 font-medium leading-6 text-gray-500 hover:text-gray-900">
                About
              </a>
            </Link>
            <Link href="/#compare">
              <a className="mr-6 font-medium leading-6 text-gray-500 hover:text-gray-900">
                Compare
              </a>
            </Link>
            <Link href="/#pricing">
              <a className="mr-6 font-medium leading-6 text-gray-500 hover:text-gray-900">
                Pricing
              </a>
            </Link>
            <div className="lg:hidden">
              <Link href="mailto:?subject=Note to self: Check out this tool to transcribe YouTube videos with one click :)&body=https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff">
                <a
                  className="text-purple-500 transition duration-150 ease-out"
                  onClick={() =>
                    logEvent(SEND_LINK_CLICKED, { source: 'header' })
                  }>
                  Email me the link
                </a>
              </Link>
            </div>
          </div>
        </div>
        <Link href="https://chrome.google.com/webstore/detail/youtube-transcripts/cijnpkfpdlieljkblckfggiiflecfmff">
          <a
            onClick={() => logEvent(INSTALL_CLICKED, { source: 'header' })}
            target="_blank"
            className="z-20 hidden px-4 py-2 text-base font-medium leading-6 text-white bg-purple-500 border border-transparent rounded-full shadow-sm lg:block whitespace-nowrap hover:bg-purple-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
            Grow my channel
          </a>
        </Link>
      </nav>
    </section>
  )
}
