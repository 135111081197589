import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import Header from '../Header'
import Footer from '../Footer'
import useCookieConsent from '../../hooks/useCookieConsent'
import useAmplitude from '../../hooks/useAmplitude'

export default function Layout({ children }) {
  const { optedOut, component: CookieComponent } = useCookieConsent()
  const { optOut } = useAmplitude()

  useEffect(() => {
    if (optedOut) {
      optOut()
    }
  }, [optedOut, optOut])

  return (
    <>
      <Head>
        <title key="title">Fast, Accurate and Cheap YouTube Transcripts </title>
        <link
          rel="canonical"
          href="https://www.tubetranscripts.com/"
          key="canonical"
        />
        <meta
          name="description"
          content="An SEO boost, better UX and reach a wider audience. Why aren't you adding transcripts to your YouTube videos?"
          key="description"
        />
        <meta
          name="keywords"
          content="youtube transcripts, youtube subtitles, get transcript of youtube video, youtube video to text transcription, transcribe youtube video to text"
          key="keywords"
        />

        <meta
          property="og:title"
          content="Fast, Accurate and Cheap YouTube Transcripts"
          key="og-title"
        />
        <meta
          property="og:description"
          content="An SEO boost, better UX and reach a wider audience. Why aren't you adding transcripts to your YouTube videos?"
          key="og-description"
        />
        <meta
          property="og:url"
          content="https://www.tubetranscripts.com/"
          key="og-url"
        />
        <meta property="og:type" content="website" key="og:type" />
        <meta
          property="og:image"
          content="https://www.tubetranscripts.com/og-image.png"
          key="og:image"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          key="twitter:card"
        />
      </Head>
      {typeof window !== 'undefined' && CookieComponent()}
      <Header />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
